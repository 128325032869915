import { useState, useMemo } from 'react';
import axios from 'axios';

import { withALErrorBoundary } from '../helpers/ALErrorBoundary';
import User from '../components/User';
import Loading from '../components/Loading';
import "./userManagement.css";

function UserManagement({ profile }) {
  const [users, setUsers] = useState(null);

  useMemo(() => {
    if (profile && profile._id) {
      axios.get(`/api/users/${profile._id}`).then((response) => {
        console.log(response, " => response...");
        if (response.data && response.data.users) {
          setUsers(response.data.users);
        }
        return null;
      }).catch((error) => {
        console.error("Error fetching users:", error);
        return null;
      });
    }
  }, [profile]);

  function updateSpecificUser(user) {
    setUsers(users.map((u) => (u._id === user._id ? user : u)));
  }

  return (
    <div className="users_container">
      <h1>Users</h1>
      {!users && (
        <Loading text="Loading users..." />
      )}
      {users && users.length > 0 && users.map((user) => {
        return (
          <User user={user} key={user._id} updateSpecificUser={updateSpecificUser} profile={profile} />
        );
      })}
    </div>
  );
}

export default withALErrorBoundary({
  name: "UserManagement",
  priority: "P1",
})(UserManagement);



