import OrderFulfillment from '../components/OrderFulfillment';
import { withALErrorBoundary } from '../helpers/ALErrorBoundary';
import { useALError } from '../helpers/ALErrorBoundary';

function Home({ profile, fulfillmentStore, setIsConnected }) {
  return (
    <div className="app">
      {(profile?.email && profile?.email.includes("analuisa.com")) && (
        <OrderFulfillment
          name={profile.firstName}
          profile={profile}
          fulfillmentStore={fulfillmentStore}
          setIsConnected={setIsConnected}
        />
      )}
    </div>
  );
}

export default withALErrorBoundary({
  name: "Home",
  priority: "P1",
})(Home);



