import loadingImg from "../assets/loading.png";

import "./loading.css";


function Loading({ text = "Loading data..." }) {

  return (
    <div className="al_loading">
      <img className="al_loading_icon al_loading_icon--reverse al_loading_icon--fast" src={loadingImg} alt="loading" />
      <div className="al_loading_text">
        {text.split("\n").map((txt, idx) => (
          <p key={idx} className="al_loading_text_line">
            {txt}
          </p>
        ))}
      </div>
    </div>
  )

}

export default Loading;