import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

import notificationIcon from "../assets/icons/notification.png";

import "./header.css";

function Header({ notificationPermissions }) {
  const { profile, primaryStore, setPrimaryStore } = useAuth();

  return (
    <div className="menu">
      <Link to="/" className="menu_item">Orders</Link>
      <Link to="/users" className="menu_item">Users</Link>

      {profile && profile.store && profile.store.length > 0 && (
        <select onChange={(e) => setPrimaryStore(e.target.value)}>
          {profile.store.map((store) => {
            return (
              <option key={store} value={store}>{store}</option>
            );
          })}
        </select>
      )}

      <div className="browser_notification">
        <div className={`browser_notification_${notificationPermissions}`}>&nbsp;</div>
        <img className="browser_notification_icon" src={notificationIcon} alt="Notification Icon" />
        <div className="browser_notification_detail">
          <p>Notifications are <b>{notificationPermissions}</b>.</p>
          {(notificationPermissions === "granted") ? (
            <i>If you're not getting notifications, take a look at your computer settings.</i>
          ) : (
            <i>Please adjust your Google Chrome settings to enable notifications.</i>
          )}
        </div>
      </div>
      
    </div>
  )

}

export default Header;