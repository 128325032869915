import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { googleLogout } from "@react-oauth/google";
import { getCookie, createCookie, deleteCookie } from "../hooks/helpers";
import { useMemo } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [primaryStore, setPrimaryStore] = useState(null);

  // Initialize user and profile from cookies/localStorage
  useEffect(() => {
    const access_token = getCookie("access_token");
    console.log("Token:", access_token);
    if (access_token) {
      setUser({ access_token: access_token });
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
            Accept: "application/json",
          },
        })
        .then(async (res) => {
          try {
            const authResponse = await axios.post("/api/auth", res.data);
            setProfile(authResponse.data.data);
            setIsLoggedIn(true);
          } catch (err) {
            console.error("Error during /api/auth:", err);
          }
        })
        .catch((err) => console.error("Failed to fetch profile:", err));
    }
  }, []);

  useMemo(() => {
    setPrimaryStore(profile?.store[0] || null);
  }, [profile]);

  const login = async (response, onLoginSuccess) => {
    try {
      const { access_token } = response;
      setUser({ access_token });
      createCookie("access_token", access_token, 365);

      const profileResponse = await axios.get(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            Accept: "application/json",
          },
        }
      );

      const profileData = profileResponse.data;

      // Call /api/auth to process the profile
      const authResponse = await axios.post("/api/auth", profileData);
      setProfile(authResponse.data.data);
      setIsLoggedIn(true);
      if (onLoginSuccess) onLoginSuccess();
    } catch (err) {
      console.error("Login failed:", err);
    }
  };

  const logout = async (onLogoutSuccess) => {
    try {
      if (profile?.email) {
        await axios.post("/api/event", {
          type: "LOGOUT",
          user: profile.email,
          sample: {},
        });
      }
      googleLogout();

      deleteCookie("access_token");
      setUser(null);
      setProfile(null);
      setIsLoggedIn(false);

      if (onLogoutSuccess) onLogoutSuccess();
    } catch (err) {
      console.error("Logout failed:", err);
    }
  };

  return (
    <AuthContext.Provider value={{
      user,
      profile,
      login,
      logout,
      isLoggedIn,
      primaryStore,
      setPrimaryStore
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};