import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Home from "./pages/Home";
import UserManagement from "./pages/UserManagement";
import Header from "./components/Header";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import { useAuth } from "./context/AuthContext";

import { AuthProvider } from "./context/AuthContext";

import { withALErrorBoundary } from "./helpers/ALErrorBoundary";

import "./App.css";

function AppContent() {
  const { profile, logout, user, primaryStore } = useAuth();
  const [isConnected, setIsConnected] = useState(true);

  // const [user, setUser] = useState(null);
  const [notificationPermissions, setNotificationPermissions] = useState(null);


  useEffect(() => {
    async function askNotificationPermission() {
      // Check if the browser supports notifications
      if (!("Notification" in window)) {
        console.log("This browser does not support notifications.");
        return;
      }
      Notification.requestPermission().then((permission) => {
        // set the button to shown or hidden, depending on what the user answers
        setNotificationPermissions(permission);
      });
    }
    askNotificationPermission();
  }, []);

  return (
    <>
      <ToastContainer limit={5} autoClose={100} />
      <PrivateRoute>
        <div className="header">
          <Header
            notificationPermissions={notificationPermissions}
          />
          <button className="logout" onClick={logout}>
            <div className="image_container">
              <img
                className="logout_image"
                alt="User Profile"
                src={profile?.picture}
                referrerPolicy="no-referrer"
              />
              <div
                className={`connected_status connected_status--${
                  isConnected ? "connected" : "disconnected"
                }`}
              />
            </div>
            Logout ({profile?.firstName} {profile?.lastName[0]}.)
          </button>
        </div>
      </PrivateRoute>

      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={
          <PrivateRoute>
            <Home
              profile={profile}
              fulfillmentStore={primaryStore}
              setIsConnected={setIsConnected}
            />
          </PrivateRoute>
        } />
        <Route path="/users" element={
          <PrivateRoute>
            <UserManagement profile={profile} />
          </PrivateRoute>
        } />
        {/*
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} />
        */}
      </Routes>
    </>
  );
}

function App() {
  return (
    <Router>
        <AuthProvider>
          <AppContent />
        </AuthProvider>
      </Router>
  );
}

export default withALErrorBoundary({
  name: "App",
  priority: "P1",
})(App);

