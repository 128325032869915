import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from "@react-oauth/google";
import { useAuth } from "../context/AuthContext";
import logoBoh from "../assets/logo512.png";
import "./login.css";

function Login() {
  const navigate = useNavigate();
  const { login, isLoggedIn } = useAuth();

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: login,
    onError: (error) => console.log("Login Failed:", error),
  });

  if (isLoggedIn) (
    navigate("/")
  )

  return (
    <div className="login">
      <img className="login_logo" src={logoBoh} alt="Logo" />
      <div className="login_content">
        <h1>Welcome to BOH</h1>
        <p>Sign in with your Google account to continue</p>
      </div>

      <button className="login_button" onClick={handleGoogleLogin}>
        <img src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg" alt="Google logo" />
        <span>Sign in with Google</span>
      </button>
    </div>
  );
}

export default Login;

