import { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { withALErrorBoundary } from '../helpers/ALErrorBoundary';
import { toast } from 'react-toastify';
import closeIcon from '../assets/icons/close.png';
import addIcon from '../assets/icons/add.png';

const STORES = [{
  "id": "soho1",
  "name": "Store 1 - SoHo",
}, {
  "id": "bleeker2",
  "name": "Store 2 - Bleecker",
}];

const ROLES = ["Admin", "Manager", "Employee"];

function User({ profile, user, updateSpecificUser }) {
  const [showAddStore, setShowAddStore] = useState(false);
  const [showAddRole, setShowAddRole] = useState(false);

  function updateUser(newUser) {
    axios
      .put(`/api/users/${profile._id}`, newUser)
      .then((response) => {
        toast(`Updated user ${user.email}`, {type: "success"});
        updateSpecificUser(response.data.result);
        setShowAddRole(false);
        setShowAddStore(false);
      })
      .catch((err) => {
        toast(`Failed to update ${user.email}...`, {type: "error"});
        console.error(err);
      });
  }

  return (
    <div className="users_container_user" key={user._id}>
      <img className="users_container_user_img" src={user.picture} alt="user" />
      <div className="users_container_user_info">
        <b>{user.firstName} {user.lastName}</b>
        <p>{user.email}</p>
      </div>

      {/* ROLES */}
      <div className="users_container_user_roles">
        <span className="users_container_user_roles_title">Roles:</span>
        <div className="user_roles">
          {user.role.map((r) => {
            return (
              <div className="user_roles_role" key={r}>
                <span>{r}</span>
                <span
                  className="user_roles_role_remove"
                  onClick={() => {
                    updateUser({ ...user, role: user.role.filter(item => item !== r)})
                  }}
                >
                  <img src={closeIcon} alt="remove" />
                </span>
              </div>
            );
          })}
        </div>
        <div className="user_roles_add">
          <span className="user_roles_add_icon" onClick={() => setShowAddRole(!showAddRole)}>
            {showAddRole ? "-" : "+"}
          </span>
          <div className="user_roles_add_detail" style={{ display: !showAddRole && "none"}}>
            {ROLES.map((r) => {
              if (user.role.includes(r)) return null;
              return (
                <div className="user_roles_role" key={r} onClick={() => updateUser({ ...user, role: [...user.role, r]})}>
                  <span>{r}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* STORES */}
      <div className="users_container_user_roles">
        <span className="users_container_user_roles_title">Stores:</span>
        <div className="user_roles">
          {user.store.map((r) => {
            return (
              <div className="user_roles_role" key={r}>
                <span>{r}</span>
                <span
                  className="user_roles_role_remove"
                  onClick={() => {
                    updateUser({ ...user, store: user.store.filter(item => item !== r)})
                  }}
                >
                  <img src={closeIcon} alt="remove" />
                </span>
              </div>
            );
          })}
        </div>
        <div className="user_roles_add">
          <span className="user_roles_add_icon" onClick={() => setShowAddStore(!showAddStore)}>
            {showAddStore ? "-" : "+"}
          </span>
          <div className="user_roles_add_detail" style={{ display: !showAddStore && "none"}}>
            {STORES.map((s) => {
              if (user.store.includes(s.name)) return null;
              return (
                <div
                  className="user_roles_role"
                  key={s}
                  onClick={() => updateUser({ ...user, store: [...user.store, s.name]})}
                >
                  <span>{s.name}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withALErrorBoundary({
  name: "User",
  priority: "P1",
})(User);

